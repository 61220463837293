














































































































































































































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n';
import Demo from '@/components/services/Demo.vue';
import QModal from '@/components/services/QModal.vue';
import { getElementIndexInParent, preload } from '@/utils/element';

export default defineComponent({
  components: { Demo, QModal },
  name: 'AILens',
  setup(props, { emit }) {
    const isServiceSub0Show = false;
    const isServiceSub1Show = false;
    const isServiceSub2Show = false;
    const lensTitle: Ref<any | null> = ref(null);
    const lensService: Ref<any | null> = ref(null);
    const lensExperience: Ref<any | null> = ref(null);
    const lensSpecial: Ref<any | null> = ref(null);
    const lensUses: Ref<any | null> = ref(null);
    const lensExperienceDemo1: Ref<any | null> = ref(null);
    const lensExperienceDemo1_1: Ref<any | null> = ref(null);
    const lensExperienceDemoPhone_1: Ref<any | null> = ref(null);
    const lensExperienceDemoPhone_1_1: Ref<any | null> = ref(null);

    onMounted(() => {
      emit('emitIsHomeFocus', false);
      initObserver();
    });

    function initObserver() {
      const io = new IntersectionObserver(
        (entries) => {
          entries.map((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
            }
          });
        },
        { threshold: 0.2 }
      );
      io.observe(lensTitle.value);
      io.observe(lensService.value);
      io.observe(lensExperience.value);
      io.observe(lensSpecial.value);
      io.observe(lensUses.value);
    }

    function handleExpImgClicked(e: Event) {
      const target = e.target as HTMLElement;
      const demo = target.firstElementChild?.classList[1]?.split('_')[1];
      // 이미지 선택 엘리먼트에 on 클래스 추가
      target.parentElement?.parentElement?.childNodes.forEach((node) => {
        (node.firstChild as HTMLElement).classList.remove('on');
      });
      target.classList.add('on');
      // 핸드폰 이미지 demo 클래스 변경
      const demoClassList = lensExperienceDemo1.value.classList;
      demoClassList.remove(demoClassList[2]);
      demoClassList.add(demo);
    }

    function handleExpDemo1Clicked(e: Event) {
      const target = e.target as HTMLElement;
      target.parentElement?.childNodes.forEach((node) => {
        (node as HTMLElement).classList.remove('on');
      });
      target.classList.add('on');

      const num = parseInt(target.classList[1].slice(4, 5));

      const phoneEl = lensExperienceDemoPhone_1_1.value as HTMLElement;
      phoneEl.querySelectorAll('a').forEach((el, idx) => {
        el.classList.remove('on');
        if (idx + 1 === num) {
          changePhone1_1Image(el);
        }
      });
    }
    function handleExpDemo1_1Clicked(e: Event) {
      const target = e.target as HTMLElement;
      const num = target.classList[0].slice(4, 5);

      const phone1El = lensExperienceDemoPhone_1.value as HTMLElement;
      phone1El.querySelectorAll('a').forEach((el) => {
        el.classList.remove('on');
        if (el.classList[1].slice(4, 5) === num) {
          el.classList.add('on');
        }
      });
      changePhone1_1Image(target);
    }

    function changePhone1_1Image(target: HTMLElement) {
      // 이미지 선택 엘리먼트에 on 클래스 추가
      target.parentElement?.parentElement?.childNodes.forEach((node) => {
        (node.firstChild as HTMLElement).classList.remove('on');
      });
      target.classList.add('on');
      // 핸드폰 이미지 하단 result 클래스 변경
      const idx = getElementIndexInParent(target.parentElement!) || 0;
      const demoClassList = lensExperienceDemo1_1.value.classList;
      demoClassList.remove(demoClassList[1]);
      demoClassList.add(`result${idx + 1}`);
    }

    return {
      i18nTxt,
      isServiceSub0Show,
      isServiceSub1Show,
      isServiceSub2Show,
      lensTitle,
      lensService,
      lensExperience,
      lensSpecial,
      lensUses,
      lensExperienceDemo1,
      lensExperienceDemo1_1,
      lensExperienceDemoPhone_1,
      lensExperienceDemoPhone_1_1,
      handleExpImgClicked,
      handleExpDemo1Clicked,
      handleExpDemo1_1Clicked
    };
  }
});
