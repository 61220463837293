export function getElementIndexInParent(elem: HTMLElement) {
  for (let i = 0; i < (elem.parentNode?.childNodes?.length ?? 0); i++) {
    if (elem.parentNode?.childNodes[i] === elem) {
      return i;
    }
  }
}

// @/assets/img 이후 경로 배열에 넣기
export function preload(imageArray: string[]) {
  imageArray.map((path) => {
    const img = new Image();
    img.src = require(`@/assets/img/${path}`);
  });
}
