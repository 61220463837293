















import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n';
import { useI18n } from '@/composables/useI18n';
import { ContactPages } from '@/router/modules/contact';

export default defineComponent({
  setup() {
    const { getLocale } = useI18n();
    const demoWrapper: Ref<any | null> = ref(null);

    const contactHandler = () => {
      window.open(`${ContactPages.ticketPage}?lang=${getLocale()}`, '_blank');
    };

    onMounted(() => {
      initObserver();
    });

    function initObserver() {
      const io = new IntersectionObserver(
        (entries) => {
          entries.map((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
            }
          });
        },
        { threshold: 0.2 }
      );
      io.observe(demoWrapper.value);
    }

    return { i18nTxt, contactHandler, demoWrapper };
  }
});
